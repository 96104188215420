<template>
    <main>
        <!-- <div class="wrapper">
            <div class="bidirectional">
                <div class="player"></div>
                <div class="cam"></div>
            </div>
            <div class="chat"></div>
        </div> -->
        <!-- <div class="parent">
            <div class="child"></div>
        </div> -->
        <div class="container grid">
            <div class="column-1 sm:col-12 md:col-9">
                <div class="floating-window"></div>
            </div>
            <div class="column-2 sm:col-12 md:col-3"></div>
        </div>
    </main>
</template>

<script setup>
import { enterFalseFullScreen } from "../../utils/players.js";

enterFalseFullScreen();
</script>

<style scoped>
main {
    height: 100%;
}
.container {
    height: 100vh;
}

.column-1 {
    background-color: black;
}

.floating-window {
    background-color: red;
}

.column-2 {
    background-color: green;
}

/* .wrapper {
    display: flex;
}
.bidirectional {
    flex: 4;
    height: 100vh;
    background-color: black;
    position: relative;
}

.chat {
    flex: 1;
    background-color: green;
    min-width: 20em;
}

.cam {
    background-color: red;
    height: 11.25em;
    width: 20em;
    z-index: 10;
    position: absolute;
    top: 10%;
    right: 10%;
    bottom: 10%;
    left: 10%;
} */
/* .parent {
    position: relative;
    width: 80%;
    height: 400px;
    background-color: #f0f0f0;
    margin: auto;
}

.child {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background-color: #ccc;
} */
/* .container {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
}

.column-1 {
    flex: 5;
    background-color: black;
    position: relative;
}

.column-2 {
    flex: 1;
    background-color: green;
}

.floating-window {
    position: absolute;
    bottom: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 20em;
    padding-top: calc(20em * (9 / 16));
    background-color: red;
} */
</style>
